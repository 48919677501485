<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('售后订单.售后单编号')" prop="id">
                <a-input
                  v-model="queryParam.id"
                  @pressEnter="handleQuery"
                  :maxLength="20"
                  :placeholder="$t('通用.输入.请输入') + $t('售后订单.售后单编号')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('售后订单.原订单编号')" prop="orderId">
                <a-input
                  v-model="queryParam.orderId"
                  @pressEnter="handleQuery"
                  :maxLength="20"
                  :placeholder="$t('通用.输入.请输入') + $t('售后订单.原订单编号')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('售后订单.售后状态')" prop="refundStatus">
                <a-select
                  mode="multiple"
                  :placeholder="$t('通用.输入.请选择') + $t('售后订单.售后状态')"
                  @change="refundStatusListChange"
                  style="width: 100%"
                  v-model="queryParam.refundStatusList"
                  allowClear
                >
                  <a-select-option
                    v-for="(item, index) in this.customDict.OrderRefundStatusEnum"
                    :value="item.type"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('售后订单.售后类型')" prop="refundType">
                  <a-select
                    :placeholder="$t('通用.输入.请选择') + $t('售后订单.售后类型')"
                    style="width: 100%"
                    v-model="queryParam.refundType"
                    allowClear
                  >
                    <a-select-option
                      v-for="(item, index) in this.customDict.GoodsRefundTypeEnum"
                      :value="item.type"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('售后订单.申请开始时间')" prop="createBeginTime">
                  <a-date-picker
                    style="width: 100%"
                    v-model="queryParam.createBeginTime"
                    :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('售后订单.申请结束时间')" prop="createEndTime">
                  <a-date-picker
                    style="width: 100%"
                    v-model="queryParam.createEndTime"
                    :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <!--              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('售后订单.售后原因')" prop="reason">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('售后订单.售后原因')" style="width: 100%" v-model="queryParam.reason" allowClear>
                    <a-select-option v-for="(item, index) in this.cancelCauseList" :value="item.id" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="6" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"
                  ><a-icon type="search" />{{ $t('通用.按钮.查询') }}</a-button
                >
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.按钮.重置') }}</a-button
                >
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" :tab="$t('销售订单.全部订单')" />
        <a-tab-pane v-for="pane in this.customDict.OrderRefundStatusEnum" :key="pane.type" :tab="pane.name">
        </a-tab-pane>
      </a-tabs>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" :disabled="multiple || !getAudit()" @click="$refs.auditForm.handleAudit(undefined,ids)" v-hasPermi="['order:refund:audit']">
          <a-icon type="download" />{{$t('售后订单.审核')}}
        </a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:refund:export']">
          <a-icon type="download" />{{ $t('通用.按钮.导出') }}
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="handleSelectExport" v-hasPermi="['order:refund:export']">
          <a-icon type="download" />{{ $t('通用.按钮.选中导出') }}
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <detail-form ref="detailForm" @ok="getList" />
      <!-- 增加修改 -->
      <order-detail-form ref="orderDetailForm" />
      <audit-form ref="auditForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="orderId" slot-scope="text, record">
          <a @click="$refs.orderDetailForm.handleDetail(record.orderId)">{{ record.orderId }}</a>
        </span>
        <template slot="refundPicList" slot-scope="text, record">
          <img
            v-for="item in record.refundPicList"
            :src="item"
            style="width: 60px; height: 62px"
            @click="handleRecordPreview(item)"
            alt="none"
          />
        </template>
        <div slot="goodsTitle" style="width: 400px">
          <a-row>
            {{ $t('销售订单.商品信息') }}
          </a-row>
          <a-row>
            <a-col :span="12">{{ $t('商品.商品名称') }}</a-col>
            <a-col :span="6">{{ $t('商品.商品主图') }}</a-col>
            <a-col :span="6">{{ $t('售后订单.售后数量') }}</a-col>
          </a-row>
        </div>
        <div slot="orderItemList" slot-scope="text, record">
          <a-table
            :showHeader="false"
            size="small"
            :bordered="false"
            rowKey="skuId"
            :pagination="false"
            :columns="goodsColumns"
            :data-source="record.orderItemList"
          >
            <template slot="picture" slot-scope="text, record">
              <div>
                <img
                  v-if="record.picture"
                  :src="record.picture"
                  style="width: 60px; height: 62px"
                  @click="handleRecordPreview(record.picture)"
                  alt="none"
                />
              </div>
            </template>
          </a-table>
        </div>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.detailForm.handleDetail(record.id)"> <a-icon type="eye" />详情</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <a-icon type="double-right" />
              {{ $t('通用.按钮.更多') }}
            </a>
            <a-menu slot="overlay">
              <a-menu-item
                v-hasPermi="['order:refund:audit']"
                v-if="record.refundStatus === 5010 || record.refundStatus === 5030"
              >
                <a
                  @click="$refs.auditForm.handleAudit(record.id, undefined, record.refundType, record.refundStatus)"
                  v-hasPermi="['order:refund:audit']"
                >
                  <a-icon type="edit" />{{ $t('售后订单.审核') }}
                </a>
                <a
                  v-if="record.orderType ==2 &&(record.ztExamineStatus == 5010 || record.refundStatus === 5030)"
                  @click="getExamineByZt(record)"
                  v-hasPermi="['order:refund:audit']"
                >
                  <a-icon type="edit" />更新审核结果
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <span slot="refundAmount" slot-scope="text, record"> ¥{{ record.refundAmount }} </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageRefund, listRefund, delRefund, getExamineByZt } from '@/api/order/refund'
import { listCancelCause } from '@/api/order/cancelCause'
import CustomDictTag from '@/components/DictCustomTag'
import { mapGetters } from 'vuex'
import moment from 'moment'
import CreateForm from './modules/CreateForm'
import DetailForm from './modules/detailForm'
import OrderDetailForm from '../sell/modules/detailForm'
import AuditForm from './modules/AuditForm'

import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Refund',
  components: {
    DetailForm,
    AuditForm,
    OrderDetailForm,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      activeKey: null,
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      cancelCauseList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: undefined,
        refundType: undefined,
        orderId: undefined,
        type: undefined,
        submitUserId: undefined,
        userId: undefined,
        shopId: undefined,
        refundStatus: undefined,
        refundStatusList: [],
        refundAmount: undefined,
        isRefundOnly: undefined,
        isReceipt: undefined,
        reason: undefined,
        refundPic: undefined,
        isDisplay: undefined,
        pageNum: 1,
        pageSize: 10
      },
      goodsColumns: [
        {
          dataIndex: 'goodsName',
          width: 200,
          align: 'center'
        },
        {
          dataIndex: 'picture',
          width: 100,
          scopedSlots: { customRender: 'picture' },
          align: 'center'
        },
        {
          width: 100,
          dataIndex: 'refundCount',
          align: 'center'
        }
      ],
      columns: [
        {
          title: this.$t('售后订单.售后单编号'),
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center',
          width: 200,
          fixed: 'left'
        },
        {
          title: this.$t('售后订单.原订单编号'),
          dataIndex: 'orderId',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'orderId' },
          align: 'center',
          width: 200,
          fixed: 'left'
        },
        {
          title: this.$t('售后订单.售后状态'),
          dataIndex: 'refundStatusName',
          align: 'center'
        },
        {
          title: this.$t('售后订单.售后类型'),
          dataIndex: 'refundTypeName',
          align: 'center'
        },
        {
          title: this.$t('中台.售后状态'),
          width: 100,
          dataIndex: 'ztExamineStatusStr',
          align: 'center'
        },
        {
          title: this.$t('中台.售后原因'),
          width: 100,
          dataIndex: 'ztExamineReason',
          align: 'center'
        },
        {
          title: this.$t('售后订单.退款金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'refundAmount',
          scopedSlots: { customRender: 'refundAmount' },
          align: 'center'
        },

        {
          width: 400,
          dataIndex: 'orderItemList',
          scopedSlots: { customRender: 'orderItemList' },
          slots: { title: 'goodsTitle' },
          align: 'center'
        },
        {
          title: this.$t('售后订单.售后原因'),
          dataIndex: 'reasonName',
          align: 'center'
        },
        {
          title: this.$t('售后订单.售后凭证'),
          scopedSlots: { customRender: 'refundPicList' },
          dataIndex: 'refundPicList',
          align: 'center'
        },
        {
          title: this.$t('售后订单.申请时间'),
          dataIndex: 'createTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 160,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getCancelCauseList()
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    refundStatusListChange() {
      this.queryParam.refundStatus = undefined
    },
    getExamineByZt(row) {
      getExamineByZt(row.id).then((response) => {
        if (response.success === true) {
          this.getList()
        }
        this.$message.success(response.message, 3)
      })
    },
    changeTab(index) {
      if (index == null) {
        index = undefined
      }
      this.queryParam.refundStatus = index
      this.getList()
    },
    /** 查询售后订单列表 */
    getList() {
      this.loading = true
      pageRefund(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    getCancelCauseList() {
      let queryParam = { type: 2 }
      listCancelCause(queryParam).then((response) => {
        this.cancelCauseList = response.data
      })
    },

    getAudit() {
      if (this.queryParam.refundStatus === 5010 || this.queryParam.refundStatus === 5030) {
        return true
      } else {
        console.log('有refundStatus', this.queryParam.refundStatusList)
        return this.queryParam.refundStatusList.includes(5010)
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        id: undefined,
        refundType: undefined,
        orderId: undefined,
        type: undefined,
        submitUserId: undefined,
        userId: undefined,
        shopId: undefined,
        refundStatus: undefined,
        refundStatusList: [],
        refundAmount: undefined,
        isRefundOnly: undefined,
        isReceipt: undefined,
        reason: undefined,
        refundPic: undefined,
        isDisplay: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = ''
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delRefund(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            const message = that.$t('通用.文本.删除成功')
            that.$message.success(message, 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('order/refund/export', that.queryParam, `售后订单_${new Date().getTime()}.xlsx`)
        },
        onCancel() {},
      })
    },
    handleSelectExport() {
      var that = this
      let queryParam = { idList: this.ids }
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk() {
          that.download('order/refund/export', queryParam, `售后订单_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        },
        onCancel() {
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        },
      })
    },
  },
}
</script>
